import Typography from 'typography';
import Wordpress2016 from 'typography-theme-wordpress-2016';
import deYoungTheme from 'typography-theme-de-young';

deYoungTheme.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
});

const typography = new Typography(deYoungTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export default typography;
export const { rhythm } = typography;
export const { scale } = typography;

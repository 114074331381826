import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {Drawer} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { rhythm, scale } from '../utils/typography';
import '../styles/layout.scss'

const ListLink = (props) => (
  <li style={{ display: 'inline-block', marginRight: '1rem' }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

const Layout = (props) => {
    const [sideBarOpen, setSideBarOpen] = React.useState(false)

    const { location, title, children } = props;
    const rootPath = `${__PATH_PREFIX__}/`;
    const blogPath = `${__PATH_PREFIX__}/blog/`;
    let header;

    return (
      <Wrapper>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header style={{ marginBottom: '1.5rem' }}>
            <MenuOutlined
              className='menu-button'
              onClick={() => {setSideBarOpen(!sideBarOpen)}}
            />
            <Drawer
              placement="left"
              closable={true}
              title="Furry Potatoes"
              onClose={() => setSideBarOpen(false)}
              visible={sideBarOpen}
            >
              <ul style={{listStyle: 'none'}}>
                <li><Link to="/blog/">Blog</Link></li>
                <li><Link to="/meet-us/">Meet Us</Link></li>
              </ul>
            </Drawer>
            <ul className="header-link-list">
              <ListLink to="/blog/">Posts</ListLink>
              <ListLink to="/meet-us/">Meet Us</ListLink>
            </ul>
            <Link to="/" style={{ textShadow: 'none', backgroundImage: 'none' }}>
              <h2 style={{ display: 'inline' }}>{title}</h2>
            </Link>
          </header>
          <main>{children}</main>
        </div>
        <Footer>
          ©
          {' '}
          {new Date().getFullYear()}
          , Built with
          {' '}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </Footer>
      </Wrapper>
    );
}

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`;

export default Layout;
